<template>
    <div>
        <!--begin::customer-->
         <div class="form-group row">
                <div class="col-lg-12 mb-5">
                    <label>{{$t('attachment_notes.name')}}</label>
                    <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.name[0] }}
                    </span>
                </div>
             <div class="col-lg-12 mb-5">
                 <b-form-checkbox size="lg" v-model="data.status" name="check-button" switch>{{$t('status')}}</b-form-checkbox>
             </div>
            </div>

        <div class="card-footer pl-0 pr-0 pb-0">
            <div class="row">
                <div class="col-lg-6">
                    <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
                    <button type="reset" class="btn btn-secondary" @click="cancel">{{ $t('cancel') }}</button>
                </div>
            </div>
        </div>

        <!--end::customer-->
    </div>
</template>
<script>
    import ApiService from "@/core/services/api.service";
    import Vue from "vue";

    export default {
        name: "form-action-perform",
        props: {
            hideModal: {type: Function},
            handlingData: {type: Function},
        },
        data() {
            return {
                mainRoute: 'sales/general-actions',
                mainRouteDependency: 'base/dependency',
                data: {
                    status: true,
                    name: "",
                },
                validation: null,
            };
        },

        methods: {
            save() {
                this.create();
            },
            cancel() {
                this.$emit('hide-modal');
                this.resetAfterSave();
                this.finishedSave();
            },
            finishedSave(su_object) {
                this.$emit('handling-data', su_object);
            },
            create() {
                ApiService.post(this.mainRoute, {
                    ...this.data,
                }).then((response) => {
                    this.$successAlert(response.data.message);
                    this.cancel();
                  Vue.prototype.$postStatus = true;
                }).catch(error => {
                    this.$errorAlert(error);
                  Vue.prototype.$postStatus = true;
                });
            },
            resetAfterSave() {
                this.data.status = false;
                this.data.name = null;
            },
        },

        mounted() {

        },
    };
</script>