<template>
  <div>
    <!--begin::customer-->
    <div>
      <h3 class="card-label">
        {{ $t('attachment_notes.attachment_notes') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="card card-custom">
        <div class="card-body">
          <div class="row mb-0">
            <div class="col-lg-6 mb-5">
              <label>{{ $t('attachment_notes.note_date') }}</label>
              <input type="date" v-model="data.note_date" class="form-control" :class="validation && validation.note_date ? 'is-invalid' : ''"/>
              <span v-if="validation && validation.note_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.note_date[0] }}
                            </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('attachment_notes.note_time') }}</label>
              <input type="time" v-model="data.note_time" class="form-control" :class="validation && validation.note_time ? 'is-invalid' : ''"/>
              <span v-if="validation && validation.note_time" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.note_time[0] }}
                            </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('attachment_notes.action_perform') }}<span class="text-danger">*</span></label>
              <div class="form-group row mb-0">
                <div class="col-sm-8">
                  <select name="" id="action_id" v-model="data.action_id" class="custom-select" :class="validation && validation.action_id ? 'is-invalid' : ''">
                    <option v-for="row in action_perform" :value="row.id" :key="row.id">{{ row.name }}</option>
                  </select>
                </div>
                <div class="col-sm-4">
                  <button class="btn btn-primary" @click="showModal">{{ $t('add_new') }}</button>
                </div>
              </div>
              <span v-if="validation && validation.action_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.action_id[0] }}
                            </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('status') }}</label>
              <select v-model="data.status" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                <option v-for="(row , index) in status_list" :key="index" :value="row.id">{{ row.title }}</option>
              </select>
              <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.status[0] }}
                            </span>
            </div>
            <div class="col-lg-12 mb-10">
              <label>{{ $t('attachment_notes.notes') }}</label>
              <textarea name="" id="notes" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
              <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.notes[0] }}
                            </span>
            </div>
            <div class="col-lg-12 mb-5">
              <b-form-checkbox size="lg" v-model="data.share_with_client" name="check-button" switch>{{ $t('attachment_notes.share_with_client') }}</b-form-checkbox>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <hr>
            </div>
            <div class="col-lg-12 mb-5 mt-5">
              <upload-file
                  @file="listenerAttachment"
                  :inner-id="'attachment'"
                  :placeholder="$t('attachment_notes.upload_attachment')"
                  :upload="dir_upload"
                  :start-link="'base'"
                  v-bind:valueprop="{name:'attachment',path:attachment_url}" v-if="reloadUploadAttachment">
              </upload-file>

            </div>
            <div class="col-lg-12 mb-4">
              <table class="table table-row-bordered">
                <thead>
                <tr>
                  <th>{{ $t('attachment_notes.file') }}</th>
                  <th>{{ $t('attachment_notes.date') }}</th>
                  <th></th>
                </tr>

                </thead>
                <tbody>
                <tr v-for="(row, index) in attachments" :key="index">
                  <td><a :href="row.file_path_url" target="_blank">{{ $t('link') }} ({{ row.file_path }})</a></td>
                  <td>{{ row.upload_date_time }}</td>
                  <td>
                    <v-icon color="danger" small @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
            <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::customer-->
    <b-modal ref="modal" size="lg" hide-footer :title="$t('attachment_notes.action_perform')">
      <action-perform-form @hide-modal="hideModal()" @handling-data="getDataAfterCreateNewActionPerform"></action-perform-form>
    </b-modal>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ActionPerformForm from "@/view/content/forms/ActionPerformForm";

export default {
  name: "form-attachment-notes",
  components: {'action-perform-form': ActionPerformForm},
  data() {
    return {
      mainRoute: 'sales/attachment-notes',
      mainRouteDependency: 'base/dependency',
      dir_upload: 'sales',

      //     $table->date('note_date');
      // $table->date('note_time');
      // $table->morphs('type');
      // $table->unsignedBigInteger('action_id');
      // $table->boolean('share_with_client')->default(0)->
      // $table->text('notes')->nullable();
      // $table->tinyInteger('status')->default(0)->comment('0 >pending, 1>done');

      data: {
        note_date: null,
        note_time: null,
        action_id: null,
        share_with_client: false,
        notes: null,
        status: 0,
        type_type: this.$route.params.type ? this.$route.params.type : null,
        type_id: this.$route.params.type_id,
      },
      idEditing: this.$route.params.type_id ? this.$route.params.type_id : null,
      type_type: this.$route.params.type ? this.$route.params.type : null,
      isEditing: false,
      attachment_url: null,
      reloadUploadAttachment: true,

      action_perform: [],
      status_list: [],

      attachments: [],

      attachment_form: {
        file_path_url: null,
        file_path: null,
        upload_date_time: null
      },
      validation: null,
    };
  },

  methods: {
    save() {
      // if (this.isEditing) {
      //     this.update();
      // } else {
      //     this.create();
      // }
      this.create();
    },

    create() {
      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
        attachments: this.attachments
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        let _page;
        if (this.type_type == 'SalesInvoice') {
          _page = 'sales_invoices.index';
        } else if (this.type_type == 'SalesInvoice') {
          _page = 'estimations.index';
        } else {
          _page = 'employees.index';
        }
        this.$router.push({name: _page});

      })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      ApiService.put(`${this.mainRoute}/${this.idEditing}`, {
        ...this.data,
        attachments: this.attachments
      }).then((response) => {
        this.validation = null;
        this.$router.push({name: 'sales_invoices.index'});
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      await ApiService.get(`${this.mainRoute}/${this.idEditing}`, {params: {type_type: this.type_type, type_id: this.idEditing}}).then((response) => {
        this.isEditing = true;
        if (response.data.data && response.data.data.type_id) {
          this.data.note_date = response.data.data.note_date;
          this.data.note_time = response.data.data.note_time;
          this.data.action_id = response.data.data.action_id;
          this.data.share_with_client = response.data.data.share_with_client;
          this.data.notes = response.data.data.notes;
          this.data.status = response.data.data.status;
          if (response.data.data.attachments) {
            this.attachments = response.data.data.attachments;
          } else {
            this.attachments = [];
          }

        }

      });
    },


    getActionPerform() {
      ApiService.get(this.mainRouteDependency + "/general_actions").then((response) => {
        this.action_perform = response.data.data;
      });
    },
    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/attachment_status").then((response) => {
        this.status_list = response.data.data;
      });
    },


    addItemRowToList() {
      this.attachments.unshift(this.attachment_form);
      this.attachment_form = {
        file_path_url: null,
        file_path: null,
        upload_date_time: null
      };
    },
    removeItemRowFromList(index) {
      this.attachments.splice(index, 1);
    },

    showModal() {
      this.$refs['modal'].show()
    },
    hideModal() {
      this.$refs['modal'].hide();
    },
    getDataAfterCreateNewActionPerform(object) {
      let promise = this.getActionPerform();
      Promise.all([promise]).then(() => {
        this.data.action_id = object.id;
      })
    },

    listenerAttachment(event) {
      if (event && event.name) {
        this.attachment_form.file_path_url = event.pathDB;
        this.attachment_form.file_path = event.name;
        this.attachment_form.upload_date_time = event.upload_date_time;

        this.addItemRowToList();
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.add_notes_attachments")}]);
    this.getActionPerform();
    this.getStatusList();
    this.getData();

  },
};
</script>